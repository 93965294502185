import fetch from './common'
/**
 * 测试get接口
 * @param {*} id 测试数据得id 
 */

//登录
export const loginApi = (params)=>fetch('/admin/login/loginByAccount',params,"post")
//退出登录
export const loginOut = (params)=>fetch('/admin/login/loginOut',params,"post")
//查询活动列表
export const activityList = (params)=>fetch('/admin/systemConfigure/activityList',params)
//查询活动方案
export const redRainPlanList = (params)=>fetch('/admin/redRain/redRainPlanList',params)
// 新增活动
export const addRedRain = (params)=>fetch('/admin/redRain/addRedRain',params,'post')
// 获取系统活动推送周期
export const getSysPushConfig = (params)=>fetch('admin/systemConfigure/getSysPushConfig',params,'GET')
// 获取门店活动
export const companyActivityList = (params)=>fetch('/admin/redRain/redRainList',params,'GET')
//新增红包雨活动方案
export const addRedRainPlan = (params)=>fetch('/admin/redRain/addRedRainPlan',params,'post')

//获取小程序广告列表
export const appletAdList = (params)=>fetch('admin/ad/appletAdList',params,'GET')
// 添加小程序广告
export const addAppletAd = (params)=>fetch('/admin/ad/addAppletAd',params,'post')
// 获取小程序广告详情
export const appletAdDetail = (params)=>fetch('admin/ad/appletAdDetail',params,'GET')
// 修改小程序广告
export const editAppletAd = (params)=>fetch('/admin/ad/editAppletAd',params,'post')

//获取评分页广告刘表
export const getPremiseList = (params)=>fetch('admin/ad/scoreAdList',params,'GET')
// 获取场所
export const getPremiseListDell = (params)=>fetch('/admin/ad/getPremiseList',params)
// 添加评分广告
export const addScoreAd = (params)=>fetch('/admin/ad/addScoreAd',params,'post')
//获取评分广告的详情
export const scoreAdDetail = (params)=>fetch('admin/ad/scoreAdDetail',params)
//修改评分广告
export const editScoreAd = (params)=>fetch('/admin/ad/editScoreAd',params,'post')
//修改门店活动启停状态 状态更改统一用这一个接口
// export const editCompanyActivityStatus = (params)=>fetch('/admin/systemConfigure/editCompanyActivityStatus',params,'post')
export const editStatus = (params)=>fetch('/admin/common/editStatus',params,'post')
//品牌列表
export const brandList = (params)=>fetch('/admin/sys/brandList',params,'get')
//添加品牌
export const addBrand = (params)=>fetch('/admin/sys/addBrand',params,"post")
//编辑品牌
export const editBrand = (params)=>fetch('/admin/sys/editBrand',params,"post")
//删除品牌
export const delBrand = (params)=>fetch('/admin/sys/delBrand',params,"post")

//获取门店列表
export const storeList = (params)=>fetch('/admin/sys/storeList',params,'get')
//添加门店
export const addStore = (params)=>fetch('/admin/sys/addStore',params,'post')
//修改门店
export const editStore = (params)=>fetch('/admin/sys/editStore',params,'post')
//删除门店
export const delStore = (params)=>fetch('/admin/sys/delStore',params,'post')

// 用户列表
export const userList = (params)=>fetch('/admin/sys/userList',params,'get')
//新增用户
export const addUser = (params)=>fetch('/admin/sys/addUser',params,'post')
//获取菜单列表
export const menuList = (params)=>fetch('/admin/sys/menuList',params,'get')
//修改用户
export const editUser = (params)=>fetch('/admin/sys/editUser',params,'post')
//删除用户
export const delUser = (params)=>fetch('/admin/sys/delUser',params,'post')

//获取金币发放规则
export const getGrantRule = (params)=>fetch('/admin/sys/getGrantRule',params,'get')
//获取平台兑换规则
export const getExchangeRule = (params)=>fetch('/admin/sys/getExchangeRule',params,'get')

//门店活动列表
export const getActivityList = (params)=>fetch('/admin/coins/getActivityList',params,'get')
//修改门店活动状态 废弃
export const saveActivityStatus = (params)=>fetch('/admin/coins/saveActivityStatus',params,'post')
//获取虚拟会员列表及优惠劵数据
export const getProductList = (params)=>fetch('/admin/coins/getProductList',params,'get')
//新增金币活动
export const addActivity = (params)=>fetch('/admin/coins/addActivity',params,'post')
//获取门店活动详情
export const getActivityInfo = (params)=>fetch('/admin/coins/getActivityInfo',params,'get')

//获取发卡活动列表
export const fagetActivityList = (params)=>fetch('/admin/stamps/getActivityList',params,'get')
// 添加and编辑
export const saveStampsActivity = (params)=>fetch('/admin/stamps/saveStampsActivity',params,'post')
// 更改活动状态
export const changeStatus = (params)=>fetch('/admin/stamps/changeStatus',params,'get')
// 详情接口
export const getActivityDetail = (params)=>fetch('/admin/stamps/getActivityDetail',params,'get')
// 订单列表
export const getOrderList = (params)=>fetch('/admin/stamps/getOrderList',params,'get')
// 获取活动素材详情
export const getMaterialDetail = (params)=>fetch('/admin/stamps/getMaterialDetail',params,'get')
// 编辑素材
export const editMaterial = (params)=>fetch('/admin/stamps/editMaterial',params,'post')

























 
